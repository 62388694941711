/** @jsx jsx */
// @ts-ignore
import { Container, Text, Box, jsx, Flex, Grid } from 'theme-ui'
import { FC, Fragment } from 'react'
import Layout from '~/components/layout'
import HeroImage from '~/components/hero-image'
import MarkdownIt from '~/components/MarkdownIt'
import { GatsbyImageType } from '~/@types/models'
import ProductCarousel from '~/components/products-carousel'
import SliderArrowCustom from '~/components/products-carousel/chevron-arrow'
import HomepageHeader from '~/components/homepage/header'
import HeadingV2 from '~/componentsV2/heading'
import { OpeningsV2 } from '~/componentsV2/openings'
import { ShowroomWrapper } from '~/componentsV2/markDownPages/showroons'
import theme from '~/gatsby-plugin-theme-ui'
import { H1, H2, H3 } from '~/styled/components/typography'
import { F } from 'ramda'

const LuxuryOutletTemplate = () => {
  return (
    <Layout
      topBanner={false}
      title="Luxury Outlet"
      description="Luxury Outlet"
      headerVariant="default"
    >
      <Container
        variant="containerPrimary"
        sx={{
          backgroundColor: '#040404',
          color: '#fff',
          width: '100%',
          maxWidth: '1440px',
          margin: '0 auto',
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            '*': {
              color: '#fff !important',
            },
          }}
        >
          <Box>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <H1
                  sx={{
                    fontSize: ['48px !important', null, '68px !important'],
                    textAlign: 'center',
                    mb: 0,
                  }}
                >
                  BEN SOLEIMANI
                </H1>
                <H2
                  sx={{
                    fontSize: '36px !important',
                    fontWeight: '100 !important',
                    textAlign: 'center',
                  }}
                >
                  LUXURY OUTLET
                </H2>
                <H3 sx={{ fontSize: '32px !important', textAlign: 'center', mt: [20, 0, 60] }}>
                  OPEN NOW!
                </H3>
              </Box>
              <a
                href="https://www.google.com.br/maps/place/1830+S+La+Cienega+Blvd,+Los+Angeles,+CA+90035,+USA/@34.0436908,-118.3790224,17z/data=!3m1!4b1!4m6!3m5!1s0x80c2b9718df6390d:0xdc9d6b443ba1ed14!8m2!3d34.0436908!4d-118.3764475!16s%2Fg%2F11bw42580l?entry=ttu&g_ep=EgoyMDI1MDEyMi4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
              >
                <Text
                  sx={{
                    width: '100%',
                    textAlign: 'center',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  1830 South La Cienega Boulevard, Los Angeles, CA 90035
                </Text>
              </a>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'center',
                width: '100%',
                mt: [20, 0, 60],
                gap: 20,
              }}
            >
              <Text>HOURS:</Text>
              <Grid columns="1fr 1fr" sx={{ rowGap: '24px', columnGap: '10px' }}>
                <Flex
                  sx={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}
                >
                  <Text>MON - SAT</Text>
                  <Text>SUN</Text>
                </Flex>
                <Flex
                  sx={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'center' }}
                >
                  <Text>10:00 am - 5:00 pm</Text>
                  <Text>11:00 am - 5:00 pm</Text>
                </Flex>
              </Grid>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default LuxuryOutletTemplate
